import styled from '@emotion/styled';

export const HamburgerContainer = styled.div(props => ({
	position: 'absolute',

	'& .dark_background': {
		position: 'fixed',
		width: '100%',
		height: '100vh',
		background: 'rgba(0, 0, 0, 0.38)',
		backdropFilter: 'blur(1px)',
		top: '0',
		left: '0',
		cursor: 'pointer',

		'&.active': {
			animation: 'FADE_IN 0.4s ease',
		},
		'&.deactive': {
			animation: 'FADE_OUT 0.4s ease',
		},
	},

	'& .logout': {
		background: 'transparent',
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		padding: '8px 16px',
		marginTop: '12px',
		fontSize: '0.875rem',

		'& svg': {
			width: '20px',
			height: '20px',
		},
	},

	'.user': {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		paddingBottom: '16px',
		margin: '0 24px 8px',
		borderBottom: `1px solid ${props.theme.colors.border.primary}`,
	},
	'.user_icon': {
		color: props.theme.colors.shades.white,
		width: '40px',
		height: '40px',
		borderRadius: '40px',
		background: 'linear-gradient(135deg, #d4d4d5 50%, #f7f7f7 50%)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	aside: {
		position: 'fixed',
		top: '0',
		width: '320px',
		backgroundColor: 'white',
		height: '100vh',
		padding: '16px',
		zIndex: '1005',
		right: '0',
		transition: 'all 0.5s cubic-bezier(0, 0, 0.2, 1) 0s',

		'&.active': {
			animation: 'SLIDE_IN 0.4s ease',
		},
		'&.deactive': {
			animation: 'SLIDE_OUT 0.4s ease',
		},

		'& .header': {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			img: {
				transform: 'rotate(180deg)',
				margin: '0 auto 16px 16px',
			},

			button: {
				borderRadius: '4px',
				marginBottom: '8px',
				fontWeight: '500',
				border: '1px solid black',

				'&.open_account_btn': {
					border: 'none',
					color: '#333333',
				},
			},
		},

		'& .menu': {
			marginTop: '16px',

			'& .item': {
				padding: '14px 16px 15px',
				cursor: 'pointer',
				color: '#333333',
				fontWeight: '400',

				'&:hover': {
					borderRadius: '8px',
					background: '#FFF9EB',
				},
			},
		},
	},

	'& .divider': {
		border: '1px solid #E5E7EA',
		margin: '8px 0',
	},

	'& .lang_button': {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		width: '100%',
		padding: '14px 8px 15px',
		background: 'transparent',
		color: props.theme.colors.text.secondary,

		'& > p': {
			flex: 1,
			textAlign: 'start',
		},

		'& svg': {
			width: '20px',
			height: '20px',
		},

		'& .lang_button_arrow': {
			width: '12px',
			height: '12px',
		},
	},
}));

export const HamburgerItemContainer = styled.div(props => ({
	'& .title_dropdown': {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		height: '48px',
		padding: '0 16px',
		alignItems: 'center',
		span: {
			fontWeight: '300',
			color: 'black',
		},

		i: {
			fontSize: '1.2rem',
		},

		'&.active': {
			background: '#F7F7F7',
			borderRadius: '4px',
		},

		'& > span': {
			display: 'flex',
			alignItems: 'center',
			gap: '12px',
			'& svg': {
				width: '20px',
				height: '20px',
			},
		},
	},

	'& .simple_title': {
		display: 'flex',
		alignItems: 'center',
		color: 'black',
		fontWeight: '300',
		height: '48px',
		padding: '0 16px',
		gap: '12px',

		'&:hover': {
			background: '#F7F7F7',
			borderRadius: '4px',
		},

		'& svg': {
			width: '20px',
			height: '20px',
		},
	},

	'& .sub_menu': {
		transition: ' all 0.5s cubic-bezier(0, 0, 0.2, 1) 0s',
		height: '0px',
		overflow: 'hidden',

		'&.open': {
			height: 'fit-content',
		},

		a: {
			height: '40px',
			display: 'flex',
			alignItems: 'center',
			color: '#464F58',
			paddingLeft: '32px',
			fontWeight: '300',
			fontSize: '0.875rem',
			transition: 'all 0.1s linear 0s',
		},
	},
}));
