import { envResolver } from 'shared/utils';
import { io } from 'socket.io-client';

const accessTokenKeyName = 'access_auth_token';
const _accessToken = localStorage.getItem(accessTokenKeyName) || '{}';
const accessToken = JSON.parse(_accessToken);

export const socket = io(envResolver.SOCKET_URL, {
	extraHeaders: {
		Authorization: `Bearer ${accessToken.token}`,
	},
	autoConnect: false,
});
