import { lazy } from 'react';
import { Route } from 'shared/types/layout.type';

const AboutUs = lazy(() => import('routes/about-us'));

const Login = lazy(() => import('pages/auth/login'));
const Register = lazy(() => import('pages/auth/register'));
const ForgetPassword = lazy(() => import('pages/auth/forget-password'));

const Home = lazy(() => import('pages/home'));
// prefix: trading
const DepositWithdraw = lazy(() => import('routes/deposit-withdraw'));
const MetaTrader5 = lazy(() => import('routes/meta-trader-5'));
const TradingSymbols = lazy(() => import('routes/trading-symbols'));
const AccountType = lazy(() => import('routes/account-type'));
const EconomicCalender = lazy(() => import('routes/economic-calender'));
// prefix: platforms
const WebTrading = lazy(() => import('routes/web-trading'));
const MetaMobile = lazy(() => import('routes/meta-mobile'));
// prefix: offers
const BonousPlan = lazy(() => import('routes/BonousPlan'));
// const IBPlan = lazy(() => import('routes/ib-plan'));
const OffersAffiliate = lazy(() => import('pages/offers/affiliate'));
// prefix: landing
const AiMultiLanguage = lazy(() => import('routes/ai-multilanguage'));
const ChallengeLanding = lazy(() => import('pages/landing/challenge'));
const BlackFriday = lazy(() => import('pages/landing/black-friday'));
const ForexBonus = lazy(() => import('pages/landing/forex-bonus'));
// prefix: education
const MetaTraderDesktop = lazy(() => import('routes/education/meta-trader-desktop'));
const TransferFundsHelp = lazy(() => import('routes/education/how-to-transfer-funds'));
const DepositHelp = lazy(() => import('routes/education/how-to-deposit'));
const OpenAccountHelp = lazy(() => import('routes/education/how-to-open-account'));
const TransferHelp = lazy(() => import('routes/education/how-to-transfer'));
const WithrawHelp = lazy(() => import('routes/education/how-to-withraw'));
const KycRules = lazy(() => import('routes/education/kyc-rules'));
const MetaTraderMobile = lazy(() => import('routes/education/meta-trader-mobile'));

// prefix: dashboard
// const DashboardWallet = lazy(() => import('pages/dashboard/wallet'));
const DashboardWalletHistory = lazy(() => import('pages/dashboard/wallet-history'));
const DashboardWallet = lazy(() => import('pages/dashboard/wallet'));
const DashboardAccounts = lazy(() => import('pages/dashboard/accounts'));
const DashboardIBOverview = lazy(() => import('pages/dashboard/ib/overview'));

const DashboardIBLinks = lazy(() => import('pages/dashboard/ib/links'));
const DashboardIBAcquisitions = lazy(() => import('pages/dashboard/ib/acquisitions'));
const DashboardIBClientList = lazy(() => import('pages/dashboard/ib/client-list/main-page'));
const DashboardIBAccounts = lazy(() => import('pages/dashboard/ib/accounts'));
const DashboardIBTransactions = lazy(() => import('pages/dashboard/ib/transactions'));
const DashboardIBRewards = lazy(() => import('pages/dashboard/ib/rewards'));
const DashboardTradeHistory = lazy(() => import('pages/dashboard/trade-history'));
const DashboardProfile = lazy(() => import('pages/dashboard/profile'));
const DashboardTransactionsHistory = lazy(() => import('pages/dashboard/transactions-history'));

export default function RoutesJson(): Route[] {
	return [
		{ component: Home, route: '', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: AboutUs, route: 'about-us', layout: { type: 'normal' }, authType: 'guardless' },

		{
			route: 'login',
			component: Login,
			authType: 'no-auth',
			layout: { type: 'normal', footerDisplay: false, navbarType: 'MINIMAL', navbarDisplay: false },
		},
		{
			route: 'register',
			component: Register,
			authType: 'no-auth',
			layout: { type: 'normal', footerDisplay: false, navbarType: 'MINIMAL', navbarDisplay: false },
		},
		{
			route: 'forget-password',
			component: ForgetPassword,
			authType: 'no-auth',
			layout: { type: 'normal', footerDisplay: false, navbarType: 'MINIMAL', navbarDisplay: false },
		},

		// prefix: trading
		{ component: DepositWithdraw, route: 'trading/deposit-withdraw', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: MetaTrader5, route: 'trading/meta-trader-5', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: TradingSymbols, route: 'trading/symbols', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: AccountType, route: 'trading/trading-accounts', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: EconomicCalender, route: 'trading/economic-calender', layout: { type: 'normal' }, authType: 'guardless' },

		// prefix: platforms
		{ component: WebTrading, route: 'platforms/web-trading', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: MetaMobile, route: 'platforms/meta-mobile', layout: { type: 'normal' }, authType: 'guardless' },

		// prefix: offers
		{ component: BonousPlan, route: 'offers/bonus-plan', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: OffersAffiliate, route: 'offers/affiliate', layout: { type: 'normal' }, authType: 'guardless' },

		// prefix: landing
		{ component: AiMultiLanguage, route: 'landing/ai-multilanguage', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: ChallengeLanding, route: 'landing/challenge', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: BlackFriday, route: 'landing/black-friday', layout: { type: 'normal', footerDisplay: false }, authType: 'guardless' },
		{
			component: ForexBonus,
			route: 'landing/forex-bonus',
			layout: { type: 'normal', footerDisplay: true },
			authType: 'guardless',
		},

		// prefix: education
		{ component: MetaTraderDesktop, route: 'education/meta-trader-desktop', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: TransferFundsHelp, route: 'education/how-to-transfer-funds', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: DepositHelp, route: 'education/how-to-deposits', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: OpenAccountHelp, route: 'education/how-to-open-account', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: TransferHelp, route: 'education/how-to-transfer', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: WithrawHelp, route: 'education/how-to-withdrawals', layout: { type: 'normal' }, authType: 'guardless' },
		{ component: KycRules, route: 'education/kyc-rules', layout: { type: 'normal' }, authType: 'guardless' },
		{
			component: MetaTraderMobile,
			route: 'education/meta-trader-mobile',
			layout: { type: 'normal' },
			authType: 'guardless',
		},

		//prefix: dashboard
		{
			component: DashboardWalletHistory,
			route: 'dashboard/wallet-history',
			layout: { type: 'normal', footerDisplay: false, navbarType: 'FULL' },
			authType: 'auth',
		},
		{
			component: DashboardWallet,
			route: 'dashboard',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardAccounts,
			route: 'dashboard/accounts',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardIBOverview,
			route: 'dashboard/ib',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardIBLinks,
			route: 'dashboard/ib/links',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardIBAcquisitions,
			route: 'dashboard/ib/acquisitions',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardIBClientList,
			route: 'dashboard/ib/client-list',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardIBAccounts,
			route: 'dashboard/ib/accounts',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardIBTransactions,
			route: 'dashboard/ib/transactions',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardIBRewards,
			route: 'dashboard/ib/rewards',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		// {
		// 	component: DashboardIBTransactions,
		// 	route: 'dashboard/ib/transactions',
		// 	layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
		// 	authType: 'guardless',
		// },
		{
			component: DashboardTradeHistory,
			route: 'dashboard/trade-history',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardTradeHistory,
			route: 'dashboard/trade-history/:id',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardTransactionsHistory,
			route: 'dashboard/transactions-history',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
		{
			component: DashboardProfile,
			route: 'dashboard/profile',
			layout: { type: 'dashboard', dashboardType: 'forex_dashboard' },
			authType: 'auth',
		},
	];
}
