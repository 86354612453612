import styled from '@emotion/styled';
import { css } from '@emotion/react';

const ProfileContainer = styled.div(
	({ theme }) => css`
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		padding: 8px;

		svg {
			width: 20px;
			height: 20px;
		}

		.title {
			display: flex;
			cursor: pointer;
		}
		.menu {
			position: absolute;
			z-index: 1;
			top: 100%;
			right: 0;
			opacity: 0;
			pointer-events: none;
			padding: 20px 0 24px;
			background-color: #fff;
			border-radius: 8px;
			transition: all 0.1s linear 0;
			max-width: calc(100vh - 90px);
			overflow-y: auto;
			min-width: 310px;
		}

		.user {
			display: flex;
			align-items: center;
			gap: 8px;
			padding-bottom: 16px;
			margin: 0 24px 8px;
			border-bottom: 1px solid ${theme.colors.border.primary};
		}
		.user_icon {
			color: ${theme.colors.shades.white};
			width: 40px;
			height: 40px;
			border-radius: 40px;
			background: linear-gradient(135deg, #d4d4d5 50%, #f7f7f7 50%);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.dashboard_list {
			display: flex;
			flex-direction: column;
			gap: 0;

			li a {
				padding: 8px 24px;
				display: flex;
				align-items: center;
				gap: 8px;
				font-size: 0.875rem;
			}
			li:hover {
				background-color: ${theme.colors.background.secondary};
			}
			li:hover a svg {
				transition: all 0.1s linear;
				/* color: ${theme.colors.text.link}; */
			}
		}

		.logout {
			background: transparent;
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 8px 24px;
			margin-top: 12px;
			font-size: 0.875rem;

			/* &:hover svg {
				color: ${theme.colors.text.link};
			} */
		}

		&:hover {
			.menu {
				opacity: 1;
				pointer-events: inherit;
			}
		}
	`,
);

export { ProfileContainer };
