import { configureStore } from '@reduxjs/toolkit';
import Profile from './reducer/profile';
import BalanceVisibility from './reducer/balance-visibility';
import IBDashboard from './reducer/ib-dashboard';
import { BalanceSliceApi, DepositSliceApi, ProfileSliceApi, WithdrawSliceApi } from './query';
import socket from './reducer/socket';

export const store = configureStore({
	reducer: {
		Profile: Profile,
		BalanceVisibility: BalanceVisibility,
		IBDashboard: IBDashboard,
		socket,
		[ProfileSliceApi.reducerPath]: ProfileSliceApi.reducer,
		[BalanceSliceApi.reducerPath]: BalanceSliceApi.reducer,
		[WithdrawSliceApi.reducerPath]: WithdrawSliceApi.reducer,
		[DepositSliceApi.reducerPath]: DepositSliceApi.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat([ProfileSliceApi.middleware, BalanceSliceApi.middleware, WithdrawSliceApi.middleware, DepositSliceApi.middleware]),
});
