import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISecurityStrategies } from 'api/coinlocally/v1';
import { GETUserProfile } from 'api/coinlocally/v1/users';
import { NavigateFunction } from 'react-router-dom';
import { IProfile } from 'store/types';

const initialState: IProfile = {
	futuresEnabled: localStorage.getItem('futuresEnabled') === 'true' ? true : false || false,
	blvtEnabled: localStorage.getItem('blvtEnabled') === 'true' ? true : false || false,
	questRewardAvailable: localStorage.getItem('questRewardAvailable') === 'true' ? true : false || false,
	topMobileDownloadSkipped: localStorage.getItem('topMobileDownloadSkipped') === 'true' ? true : false || false,
	modalMobileDownloadSkipped: localStorage.getItem('modalMobileDownloadSkipped') === 'true' ? true : false || false,
	tournamentBattleModal: localStorage.getItem('tournamentBattleModal') ? Number(localStorage.getItem('tournamentBattleModal')) : 0,
	isLogin: localStorage.getItem('refresh_auth_token') !== null ? true : false,
	haveChange: false,
	email: '',
	phoneNumber: '',
	questReward: null,
	role: '---',
	haveTicket: 0,
	haveNotife: 0,
	vipLevelName: '',
	bnbBurnForFeesEnabled: false,
	isFreezed: false,
	language: '',
	userName: '',
	avatar: null,
	avatarStatus: null,
	referralType: '',
	ipCountry: 'AM',
	lastLoginActivity: {
		createdAt: 0,
		ipAddress: '',
	},
	securityActions: {
		login: [],
		actions: [],
		notification: [],
	},
	advanceKyc: {
		rejectionReason: null,
		status: null,
	},
	basicKyc: {
		rejectionReason: null,
		status: null,
	},
	corporationKyc: {
		rejectionReason: null,
		status: null,
	},
	data: null,
};

export const Profile = createSlice({
	name: 'userInfo',
	initialState,
	reducers: {
		getSecurityStrategies: (state, { payload }: PayloadAction<ISecurityStrategies>) => {
			state.securityActions.actions = payload.actions;
			state.securityActions.login = payload.login;
			state.securityActions.notification = payload.notification;
		},
		IsLogin: (state, { payload }: PayloadAction<boolean>) => {
			state.isLogin = payload;
		},
		changeIPCountry: (state, { payload }: PayloadAction<string>) => {
			state.ipCountry = payload;
		},

		isChanged: (state, action) => {
			state.haveChange = action.payload;
		},
		setUserProfile: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { IsLogin, changeIPCountry, getSecurityStrategies, isChanged, setUserProfile } = Profile.actions;

interface ILogoutAction {
	navigate: NavigateFunction;
	language: string;
}
export const logoutAction = createAsyncThunk('logout-action', ({ navigate, language }: ILogoutAction, { dispatch }) => {
	if (typeof window !== 'undefined') {
		localStorage.clear();
		dispatch(IsLogin(false));
		navigate(`/${language}/login`);
	}
});

export const GetUserProfileThunk = createAsyncThunk('get-user-profile', async (_, { dispatch }) => {
	const data = await GETUserProfile();
	dispatch(setUserProfile(data));
});
export default Profile.reducer;
