import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'cly-uikit/css/font/oswald.min.css';
import 'cly-uikit/css/font/product-sans.min.css';
import 'cly-uikit/css/global.min.css';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LanguageHandler, Router, ThemeHandler } from 'shared/helper/app-helper';

import Loader from './components/layout/loader';
import toast, { Toaster } from 'react-hot-toast';
import { socket } from 'socket';
import { useAppDispatch } from 'shared/hooks';
import { IBalance, IDeposit, IWithdraw } from 'socket/types';
import { useAppSelector } from 'shared/hooks';
import { updateUserBalance, updateUserDeposit, updateUserWithdraw } from 'store/query';

function App() {
	const [, setIsConnected] = useState(socket.connected);
	const dispatch = useAppDispatch();
	const isLogin = useAppSelector(state => state.Profile.isLogin);

	useEffect(() => {
		function onConnect() {
			setIsConnected(true);
		}

		function onDisconnect() {
			setIsConnected(false);
		}

		function onDeposit(event: IDeposit) {
			dispatch(updateUserDeposit(event));
			toast.success(`${event.amount} USDT has been deposited successfully.`);
		}

		function onBalance(event: IBalance) {
			dispatch(updateUserBalance(event));
		}

		function onWithdraw(event: IWithdraw) {
			dispatch(updateUserWithdraw(event));
			toast.success(`your withdrawal request status for ${event.amount} USDT has been changed to ${event.status.toLowerCase()}.`);
		}

		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		socket.on('balance', onBalance);
		socket.on('withdraw', onWithdraw);
		socket.on('deposit', onDeposit);

		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			socket.off('deposit', onDeposit);
			socket.off('withdraw', onWithdraw);
			socket.off('balance', onBalance);
		};
	}, []);

	useEffect(() => {
		if (isLogin) {
			socket.connect();
		}
	}, [isLogin]);
	return (
		<ThemeHandler>
			<BrowserRouter>
				<Suspense fallback={<Loader />}>
					<Toaster
						position='bottom-right'
						containerStyle={{
							zIndex: 9999999999,
						}}
					/>
					<Routes>
						<Route path='' element={<LanguageHandler />} />

						<Route path=':lng' element={<LanguageHandler />}>
							{Router()}
						</Route>
					</Routes>
				</Suspense>
			</BrowserRouter>
		</ThemeHandler>
	);
}

export default App;
