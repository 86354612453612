import toast from 'react-hot-toast';

export const copyToClipboard = (text: string) => {
	navigator.clipboard
		.writeText(text)
		.then(() => {
			toast.success('copied to clipboard successfully');
		})
		.catch(() => toast.error('failed to copy to clipboard'));
};
