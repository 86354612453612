import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MainField = styled.div({
	display: 'flex',
	width: '100%',
	height: 'calc(100vh - 64px)',

	'@media (max-width: 1218px)': {
		flexDirection: 'column',
		marginBottom: '0px',
	},
});

export const ContentField = styled.div(props => ({
	width: '100%',
	background: '#ffffff',
	padding: ' 16px',
	overflowX: 'hidden',
	paddingBottom: '40px',

	'@media (min-width: 1218px)': {
		padding: '32px 40px',
		paddingBottom: '80px',
	},
}));

export const SidebarContainer = styled.aside(
	({ theme }) => css`
		.aside_title_container {
			display: none;
		}
		.aside_title_mobile_container {
			display: flex;
			padding: 16px;
			padding-bottom: 12px;
			padding-top: 20px;

			h3 {
				color: ${theme.mode === 'dark' ? '#666666' : '#BEBEC0'};
				margin-bottom: -12px;
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				text-align: left;
				letter-spacing: 0.02em;
			}
		}
		.vertical_view {
			display: none;
		}

		.sidebar_body {
			flex: 1;
		}

		.sidebar_bottom {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			margin-bottom: 40px;
		}

		.sub_link_container {
			user-select: none;
			.sub_link_header {
				padding: 12px 20px;
				display: grid;
				align-items: center;
				grid-template-columns: 24px 1fr auto;
				gap: 12px;
				color: ${theme.colors.text.secondary};
				line-height: 19px;
				cursor: pointer;

				font-size: 16px;
				font-weight: 300;
				line-height: 19px;

				svg {
					width: 24px;
					height: 24px;
					color: ${theme.mode === 'light' ? '#464649' : '#939393'};
				}

				.drop_icon {
					transform: rotate(90deg);
					width: 20px;
					height: 20px;

					&.active {
						transform: rotate(-90deg);
					}
				}

				&:hover {
					background: ${theme.mode === 'light' ? '#E1E3E5' : '#2C2C2E'};
					&.active {
						background: ${theme.mode === 'dark' ? '#3A3A3C' : '#000000'};
						color: #ffffff;

						svg {
							color: ${theme.mode === 'light' ? '#FFFFFF' : '#FFFFFF'};
						}
					}
				}
			}

			.sub_links {
				display: flex;
				flex-direction: column;
				.sub_item {
					color: ${theme.colors.text.secondary};
					padding: 15px 20px 14px 56px;
					font-weight: 300;
					font-size: 16px;
					line-height: 19px;

					&.active {
						background: ${theme.mode === 'dark' ? '#3A3A3C' : '#000000'};
						color: #ffffff;

						svg {
							color: ${theme.mode === 'light' ? '#FFFFFF' : '#FFFFFF'};
						}
					}

					&:hover {
						background: ${theme.mode === 'light' ? '#E1E3E5' : '#2C2C2E'};
						&.active {
							background: ${theme.mode === 'dark' ? '#3A3A3C' : '#000000'};
							color: #ffffff;

							svg {
								color: ${theme.mode === 'light' ? '#FFFFFF' : '#FFFFFF'};
							}
						}
					}
				}
			}
		}

		@media (min-width: 1218px) {
			background: ${theme.mode === 'light' ? theme.colors.background.secondary : '#272729'};
			height: 100%;

			.aside_title_mobile_container {
				display: none;
				padding: 20px;
				h3 {
					margin: 0;
				}
			}

			.aside_title_container {
				display: flex;
				padding: 20px;

				h3 {
					color: ${theme.mode === 'dark' ? '#666666' : '#BEBEC0'};
					margin-bottom: -12px;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
					text-align: left;
					letter-spacing: 0.02em;
					margin: 0;
				}
			}
			.vertical_view {
				width: 240px;
				display: flex;
				height: 100%;
				flex-direction: column;
				position: sticky;
				top: 64px;
				overflow: auto;
				height: calc(100vh - 64px);

				button {
					background-color: transparent;
					border: none;
					outline: none;
				}
				.vertical_item {
					padding: 12px 20px;
					display: flex;
					align-items: center;
					gap: 12px;
					color: ${theme.colors.text.secondary};
					line-height: 19px;
					cursor: pointer;

					font-size: 16px;
					font-weight: 300;
					line-height: 19px;

					svg {
						width: 24px;
						height: 24px;
						color: ${theme.mode === 'light' ? '#464649' : '#939393'};
					}

					&.active {
						background: ${theme.mode === 'dark' ? '#3A3A3C' : '#000000'};
						color: #ffffff;

						svg {
							color: ${theme.mode === 'light' ? '#FFFFFF' : '#FFFFFF'};
						}
					}

					&:hover {
						background: ${theme.mode === 'light' ? '#E1E3E5' : '#2C2C2E'};
						&.active {
							background: ${theme.mode === 'dark' ? '#3A3A3C' : '#000000'};
							color: #ffffff;

							svg {
								color: ${theme.mode === 'light' ? '#FFFFFF' : '#FFFFFF'};
							}
						}
					}
				}
			}
		}
	`,
);
