import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { IBalance, IDeposit, IWithdraw } from 'socket/types';
import { ISocket } from 'store/types';

const initialState: ISocket = {
	balance: null,
	deposit: null,
	withdraw: null,
};

const socket = createSlice({
	name: 'socket',
	initialState: initialState,
	reducers: {
		pushDeposit: (state, { payload }: PayloadAction<IDeposit>) => {
			state['deposit'] = payload;
			return state;
		},
		clearDeposit: state => {
			state['deposit'] = null;
			return state;
		},
		pushWithdraw: (state, { payload }: PayloadAction<IWithdraw>) => {
			state['withdraw'] = payload;
			return state;
		},
		clearWithdraw: state => {
			state['withdraw'] = null;
			return state;
		},
		pushBalance: (state, { payload }: PayloadAction<IBalance>) => {
			state['balance'] = payload;
			return state;
		},
		clearBalance: state => {
			state['balance'] = null;
			return state;
		},
	},
});

export const { pushDeposit } = socket.actions;

export default socket.reducer;
