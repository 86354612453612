import { GETUserProfile, IGetUserProfile } from 'api/coinlocally/v1/users';
import { createCustomApi } from 'store/base.api';

const apis = {
	'user-profile': GETUserProfile,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const ProfileSliceApi = createCustomApi({
	reducerPath: 'profile-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getUserProfile: builder.query<IGetUserProfile, void>({
			query: () => ({ path: 'user-profile' }),
		}),
	}),
});

export const {
	useGetUserProfileQuery,
	endpoints: { getUserProfile },
} = ProfileSliceApi;

export default ProfileSliceApi;
