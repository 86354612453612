import { AddSquareOutline, FundingWalletOutline, HistoryOutline, PnlOutline, UsersOutline } from '@coinlocally/cly-core/icons';
import { useTranslation } from 'react-i18next';
import { IDashboardConfig } from 'shared/types/dashboard-config.type';

const DashboardConfig = () => {
	const { t } = useTranslation('aside');

	const aside_config: IDashboardConfig = {
		forex_dashboard: {
			title: t('forex-dashboard'),
			links: [
				{
					title: 'Wallet',
					icon: <FundingWalletOutline />,
					link: 'dashboard',
				},
				{
					title: 'Open Account',
					icon: <AddSquareOutline />,
					link: 'dashboard/accounts',
				},
				{
					title: 'IB Dashboard',
					icon: <UsersOutline />,
					link: 'dashboard/accounts',
					sub: [
						{
							title: 'Overview',
							link: 'dashboard/ib',
						},
						{
							title: 'Links',
							link: 'dashboard/ib/links',
						},
						{
							title: 'Acquisitions',
							link: 'dashboard/ib/acquisitions',
						},
						{
							title: 'Client List',
							link: 'dashboard/ib/client-list',
						},
						{
							title: 'Accounts',
							link: 'dashboard/ib/accounts',
						},
						{
							title: 'Rewards',
							link: 'dashboard/ib/rewards',
						},
						{
							title: 'Transactions',
							link: 'dashboard/ib/transactions',
						},
					],
				},
				{
					title: 'Positions & Orders',
					icon: <PnlOutline />,
					link: 'dashboard/trade-history',
				},
				{
					title: 'Transactions History',
					icon: <HistoryOutline />,
					link: 'dashboard/transactions-history',
				},
			],
		},
	};
	return aside_config;
};

export default DashboardConfig;
