import { LogoutOutline, UserBold, UserOutline } from '@coinlocally/cly-core/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardConfig from 'shared/configs/dashboard.config';
import { maskEmail, requestHandler } from 'shared/utils';
import { useAppDispatch } from 'shared/hooks';
import { IsLogin } from 'store/reducer/profile';
import { ProfileContainer } from './profile.style';
import { useGetUserProfileQuery } from 'store/query';

const Profile = () => {
	const dispatch = useAppDispatch();
	const { data } = useGetUserProfileQuery();
	const email = data ? data.email : '';
	const {
		i18n: { language },
		t,
	} = useTranslation('navbar');
	const menu = DashboardConfig();

	const LogOutClickHandler = () => {
		requestHandler.clearHeader();
		localStorage.removeItem('refresh_auth_token');
		localStorage.removeItem('access_auth_token');
		dispatch(IsLogin(false));
		window.location.reload();
	};

	return (
		<ProfileContainer>
			<div className='title'>
				<UserOutline />
			</div>

			<div className='menu'>
				<div className='user'>
					<div className='user_icon'>
						<UserBold />
					</div>
					<p>{maskEmail(email)}</p>
				</div>
				<ul className='dashboard_list'>
					{menu.forex_dashboard.links.map((item, index) => {
						const route = item.sub ? item.sub[0].link : item.link;
						return (
							<li key={`profile-link-${index}`}>
								<Link to={`/${language}/${route}`}>
									{item.icon}
									{item.title}
								</Link>
							</li>
						);
					})}
				</ul>

				<button className='logout' onClick={LogOutClickHandler}>
					<LogoutOutline />
					{t('logout')}
				</button>
			</div>
		</ProfileContainer>
	);
};

export default Profile;
