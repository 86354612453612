import { createSlice } from '@reduxjs/toolkit';
import { IForexIBAccountType } from 'api/coinlocally/v1/forex';
import { IIBDashboard } from 'store/types';

const initialState: IIBDashboard = {
	userAccountTypes: [],
	selectedAccountType: '',
	isUpdatingInfo: false,
	isUpdatingReport: false,
	info: {
		allTimePaid: null,
		referralID: null,
		acquisition: {
			registrationsCount: null,
			clicksCount: null,
		},
		totalRewards: null,
		trading: {
			activeTraders: null,
			rewards: null,
			tradedVolume: null,
		},
		pendingPayoutAmount: null,
	},
};

export const IBDashboard = createSlice({
	name: 'ib-dashboard',
	initialState,
	reducers: {
		updateUserAccountTypes: (state, action: { payload: IForexIBAccountType['types'] }) => {
			state.userAccountTypes = action.payload;
		},

		updateSelectedAccountType: (state, action: { payload: string }) => {
			state.selectedAccountType = action.payload;
		},

		changeInfoUpdatingStatus: (state, action: { payload: boolean }) => {
			state.isUpdatingInfo = action.payload;
		},

		changeInfoReportUpdatingStatus: (state, action: { payload: boolean }) => {
			state.isUpdatingReport = action.payload;
		},

		updateIBAccountInfo: (
			state,
			action: {
				payload: IIBDashboard['info'];
			},
		) => {
			state.info = action.payload;
		},
	},
});

export const {
	updateUserAccountTypes,
	updateSelectedAccountType,
	updateIBAccountInfo,
	changeInfoUpdatingStatus,
	changeInfoReportUpdatingStatus,
} = IBDashboard.actions;
export default IBDashboard.reducer;
