import type { IBaseResponse, IBaseResponseModel, IDateFilter, IPagination } from 'types/general';
import { queryBuilder, requestHandler } from 'shared/utils';
import {
	IGetWalletActivities,
	IGetWalletBalance,
	IGetWalletDepositAddress,
	IGetWalletDeposits,
	IGetWalletPublicInfo,
	IGetWalletPublicInfoRequest,
	IGetWalletTransfers,
	IGetWalletWithdrawInfo,
	IGetWalletWithdraws,
	IPostWalletTransfers,
	IPostWalletTransfersRequest,
	IPostWalletWithdraw,
	IPostWalletWithdrawRequest,
	ITransactionsHistory,
	ITransactionsHistoryRequest,
} from './wallet.dto';

const BASE_URL = 'v1/wallet';

export const GETWalletBalance = async () => {
	return requestHandler
		.call<IBaseResponseModel<IGetWalletBalance>>({ url: `${BASE_URL}/balance`, method: 'get' })
		.then(res => res.result);
};

export const GETWalletActivities = async () => {
	return requestHandler
		.call<IBaseResponseModel<IGetWalletActivities[]>>({ url: `${BASE_URL}/activities`, method: 'get' })
		.then(res => res.result);
};

export const GETWalletDepositAddress = async (network: string, asset: string) => {
	return requestHandler
		.call<IBaseResponseModel<IGetWalletDepositAddress>>({ url: `${BASE_URL}/deposit-address/${network}/${asset}`, method: 'get' })
		.then(res => res.result);
};

export const GETWalletDeposits = async (query: IPagination & IDateFilter) => {
	const queryString = queryBuilder(query);
	return requestHandler.call<IBaseResponseModel<IGetWalletDeposits[]>>({ url: `${BASE_URL}/deposits?${queryString}`, method: 'get' });
};

export const GETWalletWithdraws = async (query: IPagination & IDateFilter) => {
	const queryString = queryBuilder(query);
	return requestHandler.call<IBaseResponseModel<IGetWalletWithdraws[]>>({ url: `${BASE_URL}/withdraws?${queryString}`, method: 'get' });
};

export const GETWalletTransfers = async (query: IPagination & IDateFilter) => {
	const queryString = queryBuilder(query);
	return requestHandler.call<IBaseResponseModel<IGetWalletTransfers[]>>({ url: `${BASE_URL}/transfers?${queryString}`, method: 'get' });
};

export const GETWalletWithdrawInfo = async () => {
	return requestHandler
		.call<IBaseResponseModel<IGetWalletWithdrawInfo>>({ url: `${BASE_URL}/withdraw-info`, method: 'get' })
		.then(res => res.result);
};

export const POSTWalletWithdraws = async (data: IPostWalletWithdrawRequest) => {
	return requestHandler.call<IPostWalletWithdraw[]>({ url: `${BASE_URL}/withdraws`, method: 'post', data });
};

export const POSTWalletTransfers = async (data: IPostWalletTransfersRequest) => {
	const res = await requestHandler.call<IBaseResponseModel<IPostWalletTransfers>>({
		url: `${BASE_URL}/transfers`,
		method: 'post',
		data,
	});

	return res;
};

export const GETForexTransactionsHistory = async (request: ITransactionsHistoryRequest) => {
	const params = queryBuilder(request);

	const res = await requestHandler.call<IBaseResponse<Array<ITransactionsHistory>>>({
		url: `${BASE_URL}/transactions?${params}`,
		method: 'get',
	});

	return res;
};

export const GETForexTransactionsHistoryCsv = async () => {
	const res = await requestHandler.call<any>({
		responseType: 'blob',
		url: `${BASE_URL}/transactions/csv`,
		method: 'get',
	});

	return res;
};

export const GETWalletPublicInfo = async (data: IGetWalletPublicInfoRequest) => {
	const params = queryBuilder(data);

	return requestHandler
		.call<IBaseResponseModel<IGetWalletPublicInfo>>({ url: `${BASE_URL}/public/info?${params}`, method: 'get' })
		.then(res => res.result);
};
