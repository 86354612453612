import packageJSON from '../../../package.json';

const envResolver = {
	API_BASE_URL: process.env.REACT_APP_API_BASE_URL ?? 'https://stageapi.coinlocally.forex/',
	GOOGLE_AUTH_CLIENT_ID:
		process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? '1062362988754-9j1picl4o2202l7qd7bomosqa30tcgbe.apps.googleusercontent.com',
	VERSION: packageJSON.version,
	SOCKET_URL: process.env.REACT_APP_SOCKET_URL ?? 'wss://stageapi.coinlocally.forex',
};

export { envResolver };
