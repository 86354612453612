import { GETWalletWithdraws, IGetWalletWithdraws } from 'api/coinlocally/v1/wallet';
import { IWithdraw } from 'socket/types';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';
import { IBaseResponse, IDateFilter, IPagination } from 'types/general';

const apis = {
	withdraw: GETWalletWithdraws,
};

const asyncBaseQuery = async (props: { path: 'withdraw' } & IPagination & IDateFilter) => {
	const { path, ...resolvedProps } = props;
	const data = await apis[path](resolvedProps);
	return { data: data };
};

const WithdrawSliceApi = createCustomApi({
	reducerPath: 'withdraw-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getUserWithdraw: builder.query<IBaseResponse<IGetWalletWithdraws[]>, IPagination & IDateFilter>({
			query: data => ({ path: 'withdraw', ...data }),
		}),
	}),
});

export const updateUserWithdraw = (data: IWithdraw) => (dispatch: AppDispatch) => {
	dispatch(
		WithdrawSliceApi.util.updateQueryData('getUserWithdraw', {}, draft => {
			if (draft) {
				const index = draft.result.findIndex(item => item.txId === data.txId);

				if (index === -1) {
					draft.result = [...draft.result, data];
				} else {
					const _result = [...draft.result];
					_result[index] = data;
					draft.result = _result;
				}
				return draft;
			}
		}),
	);
};

export const { useGetUserWithdrawQuery } = WithdrawSliceApi;

export default WithdrawSliceApi;
