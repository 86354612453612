import React, { type ReactNode, useState } from 'react';
import { HamburgerContainer, HamburgerItemContainer } from './hamburger-menu.style';
import arrow_left from 'assets/img/navbar/arrow_left.svg';
import Menu from 'utils/navbar.config';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashboardConfig from 'shared/configs/dashboard.config';
import { FC } from 'react';
import { ArrowDownBold, GlobalOutline, LogoutOutline, UserBold } from '@coinlocally/cly-core/icons';
import { maskEmail, requestHandler } from 'shared/utils';
import { useAppDispatch } from 'shared/hooks';
import { IsLogin } from 'store/reducer/profile';
import { useGetUserProfileQuery } from 'store/query';
import { ChangeLanguageModal, ClyCoreConfig } from '@coinlocally/cly-core';

const HamburgerItem: FC<{
	title: string;
	link?: string;
	href?: string;
	subMenu: { title: string; link: string }[] | null;
	onOpen: (title: string) => void;
	open: string;
	icon?: ReactNode;
}> = ({ title, link, subMenu, onOpen, open, href, icon }) => {
	const language = useTranslation().i18n.language;

	return (
		<HamburgerItemContainer>
			{!subMenu ? (
				href ? (
					<a href={href} className='simple_title'>
						{icon}
						{title}
					</a>
				) : (
					<Link className='simple_title' to={`/${language}${link?.startsWith('/') ? link : `/${link}`}`}>
						{icon}
						{title}
					</Link>
				)
			) : (
				<>
					<div className={`title_dropdown ${open === title ? 'active' : ''}`} onClick={() => onOpen(open === title ? '' : title)}>
						<span>
							{icon} {title}
						</span>
						<i className='fal fa-angle-down'></i>
					</div>
					<div className={`sub_menu ${open === title ? 'open' : ''}`}>
						{subMenu.map((item, index) => (
							<Link
								to={`/${language}${item.link?.startsWith('/') ? item.link : `/${item.link}`}`}
								key={`${title}_sub_menu_${title}_${index}`}
							>
								{item.title}
							</Link>
						))}
					</div>
				</>
			)}
		</HamburgerItemContainer>
	);
};

const languages = {
	en: 'English',
	ar: 'العربية',
	ru: 'русский',
	fa: 'فارسی',
	de: 'deutsch',
	es: 'español',
};

const HamburgerMenu: React.FC<{ status: boolean; onClose: () => void; type: 'general' | 'profile' }> = ({ status, onClose, type }) => {
	const { t } = useTranslation('navbar');
	const [innerStatus, setInnerStatus] = useState<boolean>(status);
	const onCloseHandler = () => {
		setInnerStatus(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};
	const [lngModalStatus, setLngModalStatus] = useState<boolean>(false);
	const menu = Menu();
	const dashboardMenu = DashboardConfig();

	const { data } = useGetUserProfileQuery();
	const email = data ? data.email : '';

	const [opendHamburgerMenuItem, setOpendHamburgerMenuItem] = useState<string>('');
	const { i18n } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const LogOutClickHandler = () => {
		requestHandler.clearHeader();
		localStorage.removeItem('refresh_auth_token');
		localStorage.removeItem('access_auth_token');
		dispatch(IsLogin(false));
		window.location.reload();
	};

	const onChangeLanguageHandler = (lng: string) => {
		if (lng !== i18n.language) {
			ClyCoreConfig.LANGUAGE = lng as 'en' | 'ru' | 'fa' | 'ar' | 'de' | 'es';
			navigate(location.pathname.replace(i18n.language, lng), { replace: true });
			i18n.changeLanguage(lng);
		}
	};

	return (
		<HamburgerContainer>
			<div className={`dark_background ${innerStatus ? 'active' : 'deactive'}`} onClick={onCloseHandler}></div>
			<aside className={innerStatus ? 'active' : 'deactive'}>
				{type === 'general' ? (
					<>
						<div className='header'>
							<img src={arrow_left} alt='' className='back' onClick={onCloseHandler} />
							{menu.map((item: any, index: any) => (
								<HamburgerItem
									title={item.title}
									link={item.link}
									href={item.href}
									subMenu={item.subMenu ?? null}
									key={`${item.title}_${index}`}
									open={opendHamburgerMenuItem}
									onOpen={title => setOpendHamburgerMenuItem(title)}
								/>
							))}
						</div>

						<button className='lang_button' onClick={() => setLngModalStatus(true)}>
							<GlobalOutline />
							<p>{languages[ClyCoreConfig.LANGUAGE]}</p>
							<ArrowDownBold className='lang_button_arrow' />
						</button>

						{lngModalStatus ? (
							<ChangeLanguageModal
								status={lngModalStatus}
								setStatus={setLngModalStatus}
								onLanguageClick={onChangeLanguageHandler}
							/>
						) : null}
					</>
				) : (
					<>
						<div className='header'>
							<img src={arrow_left} alt='' className='back' onClick={onCloseHandler} />
						</div>

						<div className='user'>
							<div className='user_icon'>
								<UserBold />
							</div>
							<p>{maskEmail(email)}</p>
						</div>

						{dashboardMenu.forex_dashboard.links.map((item, index) => (
							<HamburgerItem
								title={item.title}
								link={item.link}
								subMenu={item.sub ?? null}
								key={`${item.title}_${index}`}
								open={opendHamburgerMenuItem}
								onOpen={title => setOpendHamburgerMenuItem(title)}
								icon={item.icon}
							/>
						))}

						<button className='logout' onClick={LogOutClickHandler}>
							<LogoutOutline />
							{t('logout')}
						</button>
					</>
				)}
			</aside>
		</HamburgerContainer>
	);
};

export default HamburgerMenu;
