import { GETWalletBalance, IGetWalletBalance } from 'api/coinlocally/v1/wallet';
import { IBalance } from 'socket/types';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	'user-balance': GETWalletBalance,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const BalanceSliceApi = createCustomApi({
	reducerPath: 'balance-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getUserBalance: builder.query<IGetWalletBalance, void>({
			query: () => ({ path: 'user-balance' }),
		}),
	}),
});

export const updateUserBalance = (data: IBalance) => (dispatch: AppDispatch) => {
	dispatch(
		BalanceSliceApi.util.updateQueryData('getUserBalance', undefined, draft => {
			if (draft) {
				let index = -1;
				switch (data.type) {
					case 'FOREX':
						index = draft.forexBalance.findIndex(item => item.symbol.toLowerCase() === data.symbol.toLowerCase());
						if (index > -1) {
							draft.forexBalance[index].balance = data.finalBalance;
						}
						break;
					case 'NORMAL':
						index = draft.normalBalance.findIndex(item => item.symbol.toLowerCase() === data.symbol.toLowerCase());
						if (index > -1) {
							draft.normalBalance[index].balance = data.finalBalance;
						}
						break;
				}
			}
		}),
	);
};

export const { useGetUserBalanceQuery } = BalanceSliceApi;

export default BalanceSliceApi;
