import type { IBaseResponseModel } from 'types/general';
import type {
	IOAuthRequest,
	IGetSessionTokens,
	IGoogleAuthConfirmOtps,
	IRegisterRequest,
	IGetUserProfile,
	IForgetPasswordRequest,
	ILoginRequest,
	IUserProfileInfo,
	IPostEmailLang,
	IGetSessionVerifyOTP,
	IPostUserOtp,
	IPostUserOtpRequest,
	IPostUserResetPassword,
	IPutUserProfile,
	IPutUserProfileRequest,
	IPutUsersPasswordRequest,
	IPutUsersPassword,
} from './users.dto';
import { requestHandler } from 'shared/utils';
const BASE_URL = 'v1/users';

export const POSTEmailLang = async (data: { language: 'FA' | 'DE' | 'HY' | 'ES' | 'RU' | 'AR' | 'EN' | null }) => {
	return requestHandler.call<IPostEmailLang>({ url: `${BASE_URL}/language`, method: 'post', data }).then(res => res.result);
};

export const GETUserProfile = async () => {
	return requestHandler.call<IBaseResponseModel<IGetUserProfile>>({ url: `${BASE_URL}/profile`, method: 'get' }).then(res => res.result);
};

export const PUTUserProfile = async (data: IPutUserProfileRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IPutUserProfile>>({ url: `${BASE_URL}/profile`, method: 'put', data })
		.then(res => res.result);
};

export const PUTUserPassword = async (data: IPutUsersPasswordRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IPutUsersPassword>>({ url: `${BASE_URL}/password`, method: 'put', data })
		.then(res => res.result);
};

export const GETUserProfileInfo = async () => {
	return requestHandler
		.call<IBaseResponseModel<IUserProfileInfo>>({ url: `${BASE_URL}/profile-info`, method: 'get' })
		.then(res => res.result);
};

export const POSTUserRegister = async (data: IRegisterRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IGetSessionVerifyOTP | IGetSessionTokens>>({ url: `${BASE_URL}`, method: 'post', data })
		.then(res => res.result);
};

export const POSTUserResetPassword = async (data: IForgetPasswordRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IPostUserResetPassword | undefined>>({ url: `${BASE_URL}/reset-password`, method: 'post', data })
		.then(res => res.result);
};

export const POSTUserSession = async (data: ILoginRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IGetSessionVerifyOTP | IGetSessionTokens>>({ url: `${BASE_URL}/session`, method: 'post', data })
		.then(res => res.result);
};

export const POSTUserSessionGoogleAuth = async (data: IOAuthRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IGoogleAuthConfirmOtps | IGetSessionTokens>>({
			url: `${BASE_URL}/session/google-auth`,
			method: 'post',
			data,
		})
		.then(res => res.result);
};

export const POSTUsersOtp = async (data: IPostUserOtpRequest) => {
	return requestHandler
		.call<IBaseResponseModel<IPostUserOtp>>({
			url: `${BASE_URL}/otp`,
			method: 'post',
			data,
		})
		.then(res => res.result);
};

// TODO: check session for forex
export const DELETEUserSession = async () => {
	return requestHandler.call({ url: '/api/v1/users/session', method: 'delete' }).then(res => res);
};
