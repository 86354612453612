import { GETWalletDeposits, IGetWalletDeposits } from 'api/coinlocally/v1/wallet';
import { IDeposit } from 'socket/types';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';
import { IBaseResponse, IDateFilter, IPagination } from 'types/general';

const apis = {
	deposit: GETWalletDeposits,
};

const asyncBaseQuery = async (props: { path: 'deposit' } & IPagination & IDateFilter) => {
	const { path, ...resolvedProps } = props;
	const data = await apis[path](resolvedProps);
	return { data: data };
};

const WithdrawSliceApi = createCustomApi({
	reducerPath: 'deposit-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getUserDeposit: builder.query<IBaseResponse<IGetWalletDeposits[]>, IPagination & IDateFilter>({
			query: data => ({ path: 'deposit', ...data }),
		}),
	}),
});

export const updateUserDeposit = (data: IDeposit) => (dispatch: AppDispatch) => {
	dispatch(
		WithdrawSliceApi.util.updateQueryData('getUserDeposit', {}, draft => {
			if (draft) {
				const index = draft.result.findIndex(item => item.txId === data.txId);

				if (index === -1) {
					draft.result.push(data);
				} else {
					const _result = [...draft.result];
					_result[index] = data;
					draft.result = _result;
				}
				return draft;
			}
		}),
	);
};

export const { useGetUserDepositQuery } = WithdrawSliceApi;

export default WithdrawSliceApi;
