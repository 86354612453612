import moment from 'moment';

export type FormatOptions = 'full' | 'date' | 'time';

const FULL_FORMAT = 'YYYY/MM/DD HH:mm:ss';
const DATE_FORMAT = 'YYYY/MM/DD';
const TIME_FORMAT = 'HH:mm:ss';

/**
 * Formats a timestamp into a human-readable string.
 * @param timestamp - The timestamp in milliseconds (e.g., 1733060894320).
 * @param format - The desired format: "full" for date and time, "date" for date only, "time" for time only.
 * @returns A formatted string representing the date/time.
 */
function formatDate(timestamp: number, format: FormatOptions = 'full'): string {
	if (isNaN(timestamp)) {
		throw new Error('Invalid timestamp provided');
	}

	const date = new Date(timestamp);

	switch (format) {
		case 'full':
			return moment(date).format(FULL_FORMAT);
		case 'date':
			return moment(date).format(DATE_FORMAT);
		case 'time':
			return moment(date).format(TIME_FORMAT);
		default:
			throw new Error('Invalid format option');
	}
}

export default formatDate;
